@import "/src/styles/mixin";

.swiper-week-items {
  position: absolute !important;
  top: 210px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 35px;
  overflow: hidden;

  @media screen and (max-width: 1023px) {
    padding: 0 75px;
  }

  @media screen and (max-width: 767px) {
    position: relative !important;
    top: 10px;
    padding: 0;
  }

  .swiper {
    &-wrapper {
      padding-bottom: 32px;
    }

    &-button {
      @include arrowSwiper;
    }

    &-slide {
      max-width: 235px;
    }

    &-pagination {
      @include swiperPagination;
      display: block;
      margin-top: 24px;
      bottom: -6px !important;
      z-index: 1;

      &-bullet {
        width: 10px !important;
        height: 10px !important;
      }

      &-bullet-active {
        background: var(--primary-color) !important;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding-bottom: 24px;
    }
  }
}
